<template>
  <BaseCard class="max-w-[860px] card-shadow m-auto">
    <template #header>
      <LeftArrowIcon class="cursor-pointer absolute left-[20px]" @click="router.back()" />
      <h1 class="pt-6 px-8 inter-medium text-2xl text-gray-500">Billing</h1>
      <p class="border-b-2 text-sm px-8 inter-medium pt-2 pb-2 text-gray-500">
        Manage your billing information and invoices
      </p>
    </template>
    <template #main>
      <div class="flex flex-col px-8 pt-4 flex-1">
        <div
          v-if="user.subscriptionStatus === 'canceled' || user.subscriptionStatus === 'expired'"
          class="mb-4"
        >
          <p v-if="user.subscriptionStatus === 'canceled'" class="text-red-600 text-lg mb-4">
            Your subscription plan is over. to continue using the product click here:
          </p>
          <p v-if="user.subscriptionStatus === 'expired'" class="text-red-600 text-lg">
            Your Free Trial period is over. To continue using Lookalikes, update your plan below.
          </p>
        </div>
        <div class="border-b pb-6 pt-6">
          <div class="flex flex-1 justify-between border p-2 rounded bg-slate-100">
            <div class="flex gap-6">
              <CreditCardIcon class="self-center" />
              <div class="flex-col text-gray-500">
                <p class="font-bold">Payment method</p>
                <Loader v-if="loadingSubscription" class="w-10 mt-4" />
                <div v-else>
                  <p
                    v-if="
                      user.subscriptionStatus === 'free' ||
                      user.subscriptionStatus === 'trial' ||
                      user.subscriptionStatus === 'expired'
                    "
                  >
                    No payment method in place
                  </p>
                  <p v-else>{{ creditCardType }} credit card ending in {{ last4 }}</p>
                </div>
              </div>
            </div>

            <BaseButton
              v-if="user.subscriptionStatus === 'active'"
              class="self-center"
              text="Update"
              bg-color="green"
              :loading="loadingUpdatePayment"
              :disabled="
                loadingSubscription || loadingUpdatePayment || user.subscriptionStatus !== 'active'
              "
              @on-click="updatePayment"
            />
          </div>
        </div>

        <div class="flex flex-col pt-6 border-b pb-6">
          <p class="text-gray-500 font-bold">
            Current plan <span v-if="user.subscriptionStatus === 'active_until'"> - </span>
            <span v-if="user.subscriptionStatus === 'active_until'" class="text-red-500"
              >Canceled</span
            >
          </p>
          <div class="mt-6">
            <Loader v-if="loadingSubscription" class="w-10" />
            <div v-else>
              <p
                v-if="user.subscriptionStatus === 'trial' || user.subscriptionStatus === 'expired'"
                class="mb-4 text-gray-500"
              >
                Free Trial - ends on {{ trialEndsOn }} ({{ store.getDaysUntilFreeTrialEnds }} days
                left)
              </p>
              <p v-if="user.subscriptionStatus === 'active'" class="mb-4 text-gray-500">
                ${{ price }} {{ currency }} per month -
                {{ user.subscriptionStatus === 'active' ? 'renews on' : 'billing ends on' }}
                {{ nextBillingAt }}
              </p>

              <p v-if="user.subscriptionStatus === 'active_until'" class="mb-4 text-gray-500">
                You will not be charged or able to use the product after this date:
                {{ nextBillingAt }}
              </p>

              <p v-if="user.subscriptionStatus === 'free'" class="mb-4 text-gray-500">Free Plan</p>

              <SelectInput
                class="w-fit mb-4 mt-6"
                v-if="
                  user.subscriptionStatus !== 'active' &&
                  user.subscriptionStatus !== 'active_until' &&
                  user.subscriptionStatus !== 'free'
                "
                title="Select Plan"
                :static-options="['Essential - $59/mo', 'Professional - $99/mo']"
                @option-selected="selectPlan"
              />
              <BaseButton
                v-if="
                  user.subscriptionStatus !== 'active' &&
                  user.subscriptionStatus !== 'active_until' &&
                  user.subscriptionStatus !== 'free'
                "
                class="w-fit"
                text="Update Plan"
                :disabled="!isPlanSelected"
                bg-color="green"
                @on-click="openSubscriptionAgreementModal"
              />
              <BaseButton
                v-if="user.subscriptionStatus === 'active_until'"
                class="w-fit"
                text="Reactivate Plan"
                bg-color="green"
                :loading="loadingCancelSubscription"
                @on-click="reactivatePlan"
              />
              <BaseButton
                v-if="user.subscriptionStatus === 'active'"
                class="w-fit"
                text="Cancel Plan"
                bg-color="red"
                :loading="loadingCancelSubscription"
                :disabled="loadingCancelSubscription"
                @on-click="cancelPlanModalOpen = true"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col pt-6 border-b pb-6 text-gray-500">
          <p class="font-bold mb-6">Billing details</p>
          <div class="mb-4 text-sm">
            <span class="font-bold w-28 text-left">Name</span>
            <span class="ml-6">{{ store.getUserName }}</span>
          </div>
          <div class="mb-4 text-sm">
            <span class="font-bold w-28">Email</span>
            <span class="ml-6 text-left">{{ user.email }}</span>
          </div>
          <div class="mb-4 text-sm">
            <span class="font-bold w-28">Organization</span>
            <span class="ml-6 text-left">{{ user.organization }}</span>
          </div>
        </div>

        <div class="flex flex-col pt-6 pb-6 text-gray-500 max-h-64 overflow-y-auto">
          <p class="font-bold mb-6">Billing history</p>
          <Loader v-if="loadingInvoices" class="w-10" />
          <ul v-if="!loadingInvoices">
            <li v-for="(invoice, key) in invoices" :key="key" class="flex gap-32 mb-4">
              <span class="text-left w-28">{{ invoice.createdAt }}</span>
              <span class="text-left w-28">${{ invoice.amount }}</span>
              <a
                class="flex text-left text-primary-500 cursor-pointer text-sm"
                :href="invoice.invoiceUrl"
                >View invoice <RightArrowIcon class="self-center ml-4"
              /></a>
            </li>
          </ul>
        </div>
        <p class="text-sm inter-medium pt-6 pb-2 text-gray-500">
          For questions about billing, contact billing@hrsignal.com
        </p>
        <CancelPlanModal
          v-if="cancelPlanModalOpen"
          @confirm="cancelSubscription"
          @close="cancelPlanModalOpen = false"
        />
        <SubscriptionAgreementModal
          v-if="subscriptionAgreementModalOpen"
          @close="subscriptionAgreementModalOpen = false"
          @agree="openCheckout"
        />
      </div>
    </template>
  </BaseCard>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import BaseCard from '@/components/BaseCard.vue'
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import CreditCardIcon from '@/components/icons/CreditCardIcon.vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
import BaseButton from '@/components/BaseButton.vue'
import Loader from '@/components/Loader.vue'
import CancelPlanModal from './Components/CancelPlanModal.vue'
import SubscriptionAgreementModal from './Components/SubscriptionAgreementModal.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'

import { useUserStore } from '@/stores/user'

import { initializePaddle } from '@paddle/paddle-js'
import { captureException } from '@sentry/vue'

import axios from 'axios'

const plansIds = {
  essential: `${import.meta.env.VITE_PADDLE_ESSENTIAL_SUBSCRIPTION_PRICE_ID}`,
  professional: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_SUBSCRIPTION_PRICE_ID}`
}

const router = useRouter()
const store = useUserStore()
const user = store.user

const paddle = ref()
const loadingSubscription = ref(false)
const loadingUpdatePayment = ref(false)
const loadingCancelSubscription = ref(false)
const loadingInvoices = ref(false)
const invoicesError = ref(false)
const transactionId = ref()
const errorGettingTransactionId = ref(false)
const creditCardType = ref('')
const last4 = ref('')
const price = ref('')
const currency = ref('')
const nextBillingAt = ref('')
const invoices = ref([])
const currentDate = new Date()
const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
const cancelPlanModalOpen = ref(false)
const subscriptionAgreementModalOpen = ref(false)
const isPlanSelected = ref(false)
const selectedPlan = ref('')
const selectedPlanId = ref('')
const trialEndsOn = computed(() => {
  if (user.subscriptionStatus === 'trial' || user.subscriptionStatus === 'expired') {
    currentDate.setDate(currentDate.getDate() + store.getDaysUntilFreeTrialEnds)
    return currentDate.toLocaleDateString('en-US', dateFormatOptions)
  }
  return ''
})

onMounted(async () => {
  getInvoices()
  await getData()

  initializePaddle({
    environment: `${import.meta.env.VITE_PADDLE_ENVIRONMENT}`,
    token: `${import.meta.env.VITE_PADDLE_TOKEN}`,
    eventCallback: async function (data) {
      if (data.name == 'checkout.completed') {
        store.setSubscriptionStatusToActive()
        await getData()
        await getInvoices()
        await agreeToTerms()
      }
    }
  })
    .then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        paddle.value = paddleInstance
      }
    })
    .catch((error) => {
      captureException(error)
    })
})

const openSubscriptionAgreementModal = () => {
  subscriptionAgreementModalOpen.value = true
}

const openCheckout = () => {
  paddle.value?.Checkout.open({
    settings: { allowLogout: false },
    items: [{ priceId: selectedPlanId.value, quantity: 1 }],
    customer: {
      name: store.getUserName,
      email: user.email,
      business: { name: user.organization }
    }
  })
}

const updatePayment = async () => {
  await getUpdatePaymentTransactionId()

  paddle.value?.Checkout.open({
    transactionId: transactionId.value
  })
}

const getUpdatePaymentTransactionId = async () => {
  loadingUpdatePayment.value = true
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/billing/transactionToUpdatePayment`
    )

    transactionId.value = res.data.transactionId
  } catch (error) {
    errorGettingTransactionId.value = true
  } finally {
    loadingUpdatePayment.value = false
  }
}

const getData = async () => {
  loadingSubscription.value = true
  try {
    const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/billing`)

    creditCardType.value = res.data.cardType
    last4.value = res.data.last4
    price.value = res.data.price
    currency.value = res.data.currency
    nextBillingAt.value = res.data.nextBillingAt
  } catch (error) {
    errorGettingTransactionId.value = true
  } finally {
    loadingSubscription.value = false
  }
}

const getInvoices = async () => {
  loadingInvoices.value = true
  try {
    const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/billing/invoices`)

    invoices.value = res.data
  } catch (error) {
    invoicesError.value = true
  } finally {
    loadingInvoices.value = false
  }
}

const cancelSubscription = async () => {
  cancelPlanModalOpen.value = false

  loadingCancelSubscription.value = true

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/billing/cancelSubscription`
    )
    store.setSubscriptionStatus('active_until')
  } catch (error) {
  } finally {
    loadingCancelSubscription.value = false
  }
}

const reactivatePlan = async () => {
  loadingCancelSubscription.value = true

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/billing/removeCancelSubscription`
    )
    store.setSubscriptionStatus('active')
  } catch (error) {
  } finally {
    loadingCancelSubscription.value = false
  }
}

const agreeToTerms = async () => {
  try {
    await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/billing/agree-to-terms?subscription_plan=${selectedPlan.value}`
    )
  } catch (error) {}
}

const selectPlan = (plan) => {
  if (plan.value === 'Essential - $59/mo') {
    isPlanSelected.value = true
    selectedPlan.value = 'Essential'
    selectedPlanId.value = plansIds.essential
  } else if (plan.value === 'Professional - $99/mo') {
    isPlanSelected.value = true
    selectedPlan.value = 'Professional'
    selectedPlanId.value = plansIds.professional
  } else {
    isPlanSelected.value = false
  }
}
</script>
