<template>
  <SearchingForCandidates v-if="loading" class="self-center" />
  <div v-else class="p-4 m-auto relative">
    <BaseCardWithHeader class="mb-4" title="Selected Location" custom-css-for-header="!p-2">
      <template #main>
        <div class="p-4">
          <SearchLocationFilter />
        </div>
      </template>
    </BaseCardWithHeader>
    <BaseCardWithHeader
      class="m-auto"
      :title="`Edit Archetype: ${archetypeProfileDataLocal.name}`"
      custom-css-for-header="!p-2"
    >
      <template #main>
        <div class="flex flex-col p-4 grow-0">
          <div>
            <div class="text-gray-500 inter-medium text-sm">
              <p>
                Editing the archetype is optional and allows for more search flexibility. Tune
                current archetype values to receive the perfect candidates for you.
              </p>
              <!-- <p>The selected location for this search is: {{ selectedLocation }}</p> -->
            </div>
            <div v-if="!hasSearchResults" class="mt-2">
              <p class="text-rose-600">
                No results found for given archetype with a current position of:
                {{ archetypeCurrentPositionTitle ?? 'N\\A' }}
              </p>
              <p class="text-gray-500 inter-medium font-medium text-sm">
                To proceed with this search, please select the closest position title from the list
                below, or you can type any position title using a free text search:
              </p>
            </div>
          </div>

          <div class="flex gap-4 mt-5 grow-0">
            <BaseCardWithHeader
              title="Current Experience"
              class="flex-1"
              custom-css-for-header="!p-2"
            >
              <template #main>
                <div class="flex flex-col p-2 flex-1">
                  <div class="gap-2">
                    <div class="text-gray-500 inter-medium text-sm flex">
                      <div>
                        {{ archetypeCurrentPositionTitle }} •
                        {{
                          convertMonthsToYearsMonths(
                            archetypeProfileDataLocal.archetype_experience_in_position
                          )
                        }}

                        <span class="block mt-1">
                          <div>
                            {{ archetypeProfileDataLocal.company }} •
                            {{ archetypeProfileDataLocal.industry }}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>

                  <SuggestedTitlesAutoComplete
                    class="mt-4"
                    title="role title"
                    label-size="small"
                    :auto-complete-value="archetypeProfileDataLocal.title"
                    placeholder-text="Start typing a position title..."
                    :api-url="apiUrl"
                    :query-params="locationQueryParams"
                    :static-options="suggestedPositionTitles"
                    :show-static-options="showPositionTitlesOptions"
                    @option-selected="
                      (val) => (
                        (archetypeProfileDataLocal.title = val.title),
                        (archetypeProfileDataLocal.canonicalTitle = val.canonical_title),
                        (archetypeProfileDataLocal.revizedTitle = true)
                      )
                    "
                  />
                  <Input
                    class="mt-2"
                    title="MIN YEARS OF TOTAL ROLE EXPERIENCE"
                    label-size="small"
                    type="number"
                    label="small"
                    :min="0"
                    :max="100"
                    :value="
                      Math.round(
                        archetypeProfileDataLocal.archetype_total_experience_in_position / 12
                      )
                    "
                    @input-change="
                      (value) => (
                        (archetypeProfileDataLocal.archetype_total_experience_in_position =
                          value * 12),
                        (archetypeProfileDataLocal.revizedTotalExpInPosition = value * 12),
                        lookalikesStore.setArchetypesTotalPositionExp(value * 12)
                      )
                    "
                  />
                  <Input
                    class="mt-2"
                    title="MIN YEARS OF CAREER EXPERIENCE"
                    type="number"
                    label-size="small"
                    :min="0"
                    :max="100"
                    :value="
                      Math.round(archetypeProfileDataLocal.archetype_total_career_experience / 12)
                    "
                    @input-change="
                      (value) => (
                        (archetypeProfileDataLocal.archetype_total_career_experience = value * 12),
                        (archetypeProfileDataLocal.revizedTotalCareerPosition = value * 12),
                        lookalikesStore.setArchetypesTotalCarerExp(value * 12)
                      )
                    "
                  />
                  <BaseButton
                    class="mt-5"
                    :text="textForSearchButton ?? 'Let`s Go'"
                    text-align-center
                    @click="searchLookalikes"
                  />
                </div>
              </template>
            </BaseCardWithHeader>

            <BaseCardWithHeader title="Past Experience" custom-css-for-header="!p-2">
              <template #main>
                <div class="p-4 text-gray-500 inter-medium text-sm overflow-y-auto max-h-[290px]">
                  <p v-if="archetypeProfileDataLocal.archetype_experience.length === 0">
                    Work Experience data is not available
                  </p>

                  <ul v-if="archetypeProfileDataLocal.archetype_experience" class="list-disc">
                    <template v-for="(experience, companyName) in workExp">
                      <div class="mb-4">
                        <div class="flex">
                          <div class="w-10 h-10 self-center mr-4 justify-center items-center flex">
                            <CompanyLogo
                              :src="experience.positions[0].logo"
                              :company-name="companyName"
                              :link="
                                experience.positions[0].url ?? experience.positions[0].linkedin_url
                              "
                            />
                          </div>
                          <div>
                            <u
                              >{{ companyName }} ({{
                                experience.positions[0]
                                  ? convertMonthsToYearsMonths(
                                      experience.positions[0]['total_time_in_company']
                                    )
                                  : ''
                              }})</u
                            >

                            <li
                              v-for="(position, idx) in experience.positions"
                              :key="idx"
                              class="relative leading-9 flex"
                              :class="{
                                'cursor-pointer':
                                  `${companyName}.${idx}` !== `${Object.keys(workExp)[0]}.0`
                              }"
                              @click="toggleRemoveExpItem(`${companyName}.${idx}`)"
                            >
                              <div
                                v-if="`${companyName}.${idx}` !== `${Object.keys(workExp)[0]}.0`"
                                class="mr-2 self-center"
                              >
                                <UndoIcon
                                  v-if="deletedExpItems.includes(`${companyName}.${idx}`)"
                                />
                                <MinusIcon v-else />
                              </div>
                              <div
                                :class="{
                                  'line-through': deletedExpItems.includes(`${companyName}.${idx}`),
                                  'ml-[26px]':
                                    `${companyName}.${idx}` === `${Object.keys(workExp)[0]}.0`
                                }"
                              >
                                {{ position.title }} ({{ formatDate(position.starts_at) }} -
                                {{ formatDate(position.ends_at) ?? 'Current' }})
                              </div>
                            </li>
                          </div>
                        </div>
                      </div>
                    </template>
                  </ul>
                </div>
              </template>
            </BaseCardWithHeader>
          </div>
        </div>
      </template>
    </BaseCardWithHeader>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, toRaw, ref } from 'vue'
import { useRouter } from 'vue-router'

import { convertMonthsToYearsMonths } from '../../../../../utils'

import Input from '@/components/inputs/Input.vue'
import SuggestedTitlesAutoComplete from './SuggestedTitlesAutoComplete.vue'
import BaseCardWithHeader from '@/components/Cards/BaseCards/BaseCardWithHeader.vue'
import BaseButton from '@/components/BaseButton.vue'
import SearchLocationFilter from '@/components/SearchLocationFilter.vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
import MinusIcon from '@/components/icons/MinusIcon.vue'
import UndoIcon from '@/components/icons/UndoIcon.vue'
import SearchingForCandidates from './SearchingForCandidates.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

import axios from 'axios'
import { captureException } from '@sentry/vue'

interface IProps {
  textForSearchButton?: string
  editedSearch?: boolean
}

const emit = defineEmits(['searchingForLookalikes', 'lookalikesSearchFinished', 'error'])

const props = defineProps<IProps>()

const lookalikesStore = useLookalikesStore()
const router = useRouter()

const deletedExpItems = ref<string[]>(lookalikesStore.removedExpItems)
const hasSearchResults = ref(true)
const showPositionTitlesOptions = ref(false)
const suggestedPositionTitles = ref([])
const loading = ref(false)
const locationQueryParams = computed(
  () =>
    `countries=${JSON.stringify(lookalikesStore.countries)}&states=${JSON.stringify(lookalikesStore.states)}&cities=${JSON.stringify(lookalikesStore.cities)}`
)
const archetypeProfileDataLocal = ref(structuredClone(toRaw(lookalikesStore.archetypeProfile)))
const apiUrl = `${import.meta.env.VITE_LOOKALIKES_API}/api/position-title-auto-complete`
const archetypeCurrentPositionTitle = lookalikesStore.archetypeProfile.title

const workExp = computed(() => {
  if (lookalikesStore.archetypeProfile.archetype_experience) {
    return lookalikesStore.archetypeProfile.archetype_experience.reduce((acc, cur) => {
      if (acc[cur.company] !== undefined) {
        acc[cur.company]['positions'].push(cur)
      } else {
        acc[cur.company] = { positions: [cur] }
      }
      return acc
    }, {})
  }

  return {}
})

const formatDate = (date) => {
  if (date === null) {
    return null
  }
  const dateObj = new Date(date)

  return `${dateObj.toLocaleString('default', { month: 'short' })} ${dateObj.getFullYear()}`
}

const toggleRemoveExpItem = (companyNameAndIndex: string) => {
  if (companyNameAndIndex === `${Object.keys(workExp.value)[0]}.0`) {
    return
  }

  if (!deletedExpItems.value.includes(companyNameAndIndex)) {
    deletedExpItems.value.push(companyNameAndIndex)
  } else {
    deletedExpItems.value = deletedExpItems.value.filter((id) => id !== companyNameAndIndex)
  }

  lookalikesStore.setRemovedExpItems(deletedExpItems.value)
}

const deleteExpItemFromArchetypeExp = () => {
  console.log(archetypeProfileDataLocal.value.archetype_experience)
  archetypeProfileDataLocal.value.archetype_experience =
    archetypeProfileDataLocal.value.archetype_experience.filter(
      (expItem, idx) => !deletedExpItems.value.includes(`${expItem.company}.${idx}`)
    )
}

const searchLookalikes = async (): Promise<void> => {
  const archetypeProfileDataLocalCopy = JSON.parse(
    JSON.stringify(toRaw(archetypeProfileDataLocal.value))
  )

  deleteExpItemFromArchetypeExp()

  loading.value = true
  emit('searchingForLookalikes')

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alikes/search/${archetypeProfileDataLocal.value.archetypeCacheId}`,
      {
        linkedinProfileUrl: lookalikesStore.archetypeLiProfileUrl,
        countries: lookalikesStore.countries,
        states: lookalikesStore.states,
        cities: lookalikesStore.cities,
        archetypeProfileData: archetypeProfileDataLocal.value,
        deletedExpItems: deletedExpItems.value,
        editedSearch: props.editedSearch ?? true,
        searchId: lookalikesStore.lookalikeCurrentHistorySearchId
      }
    )

    console.log(res.data.candidateData.length)

    if (res.data.candidateData.length === 0) {
      hasSearchResults.value = false
      showPositionTitlesOptions.value = true
      suggestedPositionTitles.value = res.data.suggestedPositionTitles
    }

    if (res.data.candidateData.length > 0) {
      lookalikesStore.setLookalikeCurrentHistorySearchId(Number(res.data.searchId))
      lookalikesStore.setSearchResults(res.data)
      lookalikesStore.setArchetypeProfile(archetypeProfileDataLocalCopy)
      router.push({ name: 'searchResults', params: { searchId: res.data.searchId } })
    }
  } catch (err) {
    captureException(err)
    emit('error')
  } finally {
    loading.value = false
    emit('lookalikesSearchFinished')
  }
}
</script>
