<template>
  <div>
    <p class="c1 c28"><span class="c16"></span></p>
  </div>
  <p class="c26">
    <span class="c34">Lookalikes by HR Signal Order Form </span>
  </p>
  <ol class="c11 lst-kix_list_1-0 start" start="1">
    <li class="c30 li-bullet-0">
      <h1 style="display: inline">
        <span class="c15">SaaS Agreement. </span>
      </h1>
    </li>
  </ol>
  <p class="c22">
    <span class="c2"
      >This Order is subject to the SaaS Agreement following the Order. The SaaS Agreement is
      incorporated herein by reference.
    </span>
  </p>
  <ol class="c11 lst-kix_list_1-0" start="2">
    <li class="c30 li-bullet-0">
      <h1 style="display: inline"><span class="c15">Services.</span></h1>
    </li>
  </ol>
  <p class="c22">
    <span class="c5"
      >The Services provided under this Order include the provision of subscription services
      provided on Supplier&rsquo;s online portal and limited online support. Services provided under
      this Order are limited only to Lookalikes by HR Signal. This Order and the Services do not
      include any other SaaS services, content, or other software, unless specifically described
      within this Order form.
    </span>
  </p>
  <ol class="c11 lst-kix_list_1-0" start="3">
    <li class="c30 li-bullet-0"><span class="c15">Terms and Fees.</span></li>
  </ol>
  <p class="c33" id="h.gjdgxs">
    <span class="c2"
      >The term of this Order commences on the date the Customer signs the Order and SaaS Agreement
      electronically on Supplier&rsquo;s website or starts using the Services (whichever is earlier)
      and Supplier&rsquo;s acceptance of this Order and SaaS Agreement. Unless otherwise terminated
      earlier in accordance with the SaaS Agreement, this Order renews automatically.
    </span>
  </p>
  <p class="c33" id="h.30j0zll">
    <span class="c2">The fees for the Services are as follows:</span>
  </p>
  <table class="c0">
    <tr class="c9">
      <td class="c7" colspan="1" rowspan="1">
        <p class="c1"><span class="c6">Service</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1">
          <span class="c6">Lookalikes by HR Signal (Essential)</span>
        </p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1">
          <span class="c6">Lookalikes by HR Signal (Professional)</span>
        </p>
      </td>
      <td class="c18" colspan="1" rowspan="1">
        <p class="c1">
          <span class="c6">Lookalikes by HR Signal (Team)</span>
        </p>
      </td>
    </tr>
    <tr class="c9">
      <td class="c7" colspan="1" rowspan="1">
        <p class="c1">
          <span class="c6">Per Seat Cost Per Month (USD) </span>
        </p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">$59</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">$99</span></p>
      </td>
      <td class="c18" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">$50</span></p>
      </td>
    </tr>
    <tr class="c9">
      <td class="c7" colspan="1" rowspan="1">
        <p class="c1"><span class="c6">Number of Seats</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">1</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">1</span></p>
      </td>
      <td class="c18" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">10 or more</span></p>
      </td>
    </tr>
    <tr class="c9">
      <td class="c7" colspan="1" rowspan="1">
        <p class="c1"><span class="c6">Total Cost Per Month</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">$59</span></p>
      </td>
      <td class="c13" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">$99</span></p>
      </td>
      <td class="c18" colspan="1" rowspan="1">
        <p class="c1"><span class="c2">Variable</span></p>
      </td>
    </tr>
  </table>
  <p class="c8"><span class="c2"></span></p>
  <p class="c21">
    <span class="c2">[signed electronically by Customer]</span>
  </p>
  <p class="c22 c28"><span class="c6"></span></p>
  <p class="c8"><span class="c2"></span></p>
  <p class="c8"><span class="c2"></span></p>
  <p class="c8"><span class="c2"></span></p>
  <p class="c8"><span class="c2"></span></p>
  <hr style="page-break-before: always; display: none" />
  <p class="c8" id="h.1fob9te"><span class="c2"></span></p>
  <p class="c32">
    <span class="c25 c31">Lookalikes by HR Signal Software-as-a-Service Agreement</span>
  </p>
  <p class="c29">
    <span class="c2"
      >This Lookalikes by HR Signal Software-as-a-Service Agreement (&ldquo;SaaS Agreement&rdquo;)
      and applicable attachments or orders (&ldquo;Order&rdquo;) are the complete agreement
      regarding transactions under this agreement (together, the &ldquo;Agreement&rdquo;) under
      which Customer may order Services. Orders detail the specifics of transactions, such as
      charges and a description of the Services.
    </span>
  </p>
  <a id="id.3znysh7"></a>
  <ol class="c11 lst-kix_list_2-0 start" start="1">
    <li class="c19">
      <h1 style="display: inline"><span class="c5">Services.</span></h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5"
        >In consideration for the payment of the subscription payments made in accordance with the
        applicable Order, Supplier hereby provides the Services to Customer.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >&ldquo;Services&rdquo; means Supplier&rsquo;s subscription services made available via the
        internet as described in an Order. Services include data, documents, or other materials that
        Supplier provides to Customer (&ldquo;Materials&rdquo;).</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >The Services are available only to Authorized Users. &ldquo;Authorized User&rdquo; means
        employees (and any independent contractors performing functions comparable to employees in
        the ordinary course of business) of Customer and its affiliates who: (a) are authorized by
        Supplier; (b) are bound by the Agreement; and (c) have their own subscription. Authorized
        Users are allowed to use the Service only through their own individual account.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer may access the Services only to the extent authorized by Supplier. Customer is
        responsible for its own use of Services by its users, including Authorized Users. Customer
        is responsible for its actions and the contents of its transmissions through the Services.
        Customer is responsible for the compliance of Customer Content with this Agreement,
        including content uploaded by its users.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer may not: (a) duplicate, disassemble, reverse engineer, or otherwise reproduce
        without authorization any portion of the Services; (b) resell direct access to the Services
        to a third party; (c) scrape, steal, or copy the Services without authorization; (d)
        disclose any performance data relating to the Services; (e) sell or transfer to another
        third party Services in violation of this Agreement; (f) modify, remove, or deface any
        logos, trademarks, or copyrights from the Services, or (g) build a product, service, or
        offering that competes with Supplier or Services.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer may not use the Services for: (a) defamatory, harassing, abusive, threatening,
        obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that
        violates or infringes upon the rights of third parties; (c) activity that violates
        applicable law; (d) sending viruses, spyware, or similar computer programming routines; (e)
        discrimination, unfair treatment, or unlawful or improper hiring, firing, suspension,
        promotion, or treatment of employees, contractors, or associates; or (f) any purposes
        inconsistent with this Agreement.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer may not use Services for any purpose regulated under the Fair Credit Reporting
        Act, including, without limitation, as a factor in (a) establishing an individual&rsquo;s
        eligibility for credit or insurance or assessing risks associated with existing credit
        obligations, (b) evaluating an individual for employment purposes, (c) determining an
        individual&rsquo;s eligibility for a license or other benefit that depends on an
        applicant&rsquo;s financial responsibility or status, or (d) for any other purposes subject
        to the Fair Credit Reporting Act.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="2">
    <li class="c19">
      <h1 style="display: inline">
        <span class="c5">Customer Responsibilities.</span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer is hereby provided with limited access to the Services subject to the Agreement.
        Subject to the terms of the Agreement, Supplier hereby provides to Customer a limited,
        non-exclusive, non-transferrable (except as explicitly permitted under the assignment
        provision of the Agreement), revocable-at-any-time (for material breach of this license or
        the Agreement) license during the term of the applicable Order to access and use the
        Materials for human resources purposes and no other purpose. Except as otherwise stated in
        this section, Customer does not obtain any other rights to the Services or Materials.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >&ldquo;Customer Content&rdquo; means all content or information that Customer provides or
        authorizes access to for the Services. Except as otherwise provided in the Order, Customer
        hereby grants Supplier a limited, non-exclusive, royalty-free, transferrable, revocable upon
        the termination of the Agreement license to access, display, store, share, transmit, or
        otherwise use or process Customer Content to provide or improve the Supplier&rsquo;s
        Services. Customer warrants that it has the right and authority to provide Customer Content
        and that such materials do not infringe the rights of others or violate applicable
        law.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer is responsible for obtaining all necessary rights and permissions to enable, and
        grants such rights and permissions to, Supplier, its affiliates, and their respective
        contractors and vendors to use, provide, store, and otherwise process Customer Content in
        the Services. This includes Customer making necessary disclosures and obtaining consent, if
        required, before providing individuals&rsquo; information to Supplier.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s
        intended use; (b) taking necessary actions to order, enable, or use available features
        appropriate for its use of the Services; and (c) complying with applicable law. Supplier is
        not a consumer reporting agency.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="3">
    <li class="c4 li-bullet-0"><span class="c15">Confidentiality.</span></li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5"
        >&ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary
        information, formulae, processes, techniques, and information concerning past, present, and
        future marketing, financial, research, and development activities that may be disclosed,
        orally or in writing, to each other. Confidential Information excludes information that (a)
        was previously known to the receiving party without an obligation of confidence; (b) was
        independently developed by or for the receiving party without the use of Confidential
        Information; (c) was lawfully acquired by the receiving party from a third party which is
        not under an obligation of confidence with respect to such information; or (d) is or becomes
        publicly available through no fault of the receiving party without a breach of the
        Agreement.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Supplier shall maintain the privacy, security, and confidentiality of Confidential
        Information in accordance with the Agreement and its policies.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer shall maintain the privacy, security, and confidentiality of Confidential
        Information and its access to the Services. Customer shall use strong and secure passwords
        and keep them secure and confidential. Customer shall promptly notify Supplier in the event
        of a security breach or unauthorized use of their account. Customer is responsible for any
        damages incurred as a result of the unauthorized use of their account. The Agreement and
        Services are a valuable trade secret and confidential proprietary property of Supplier.
        Customer agrees to access and use Supplier&rsquo;s Services only as provided in this
        Agreement and to safeguard Supplier&rsquo;s trade secrets and confidential proprietary
        property.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="4">
    <li class="c19">
      <h1 style="display: inline">
        <span class="c5">Charges, Taxes, and Payment.</span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Except for any taxes based on Supplier&rsquo;s net income and property, Customer agrees to
        pay all applicable charges specified for the Services and any charges imposed by any
        authority resulting from Customer&#39;s use of the Services, including any applicable sales
        taxes.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Subscription payments are due in advance as described in the Order. Except as otherwise
        stated in the Agreement, all subscription purchases are final and non-refundable.
        Subscription renewal cancellation requests are subject to the terms set forth in section
        11.2.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not
        paid within 30 calendar days of the invoice date. Supplier may, at its sole discretion,
        terminate this Agreement if Customer has failed to pay any amount when due. The Order sets
        forth the fees for the Services.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="5">
    <li class="c19">
      <h1 style="display: inline"><span class="c5">Warranties.</span></h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <h1 style="display: inline">
        <span class="c5"
          >Each party warrants to the other that this Agreement has been duly executed and delivered
          and constitutes a valid and binding agreement enforceable against such party in accordance
          with its terms.
        </span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="6">
    <li class="c19">
      <h1 style="display: inline">
        <span class="c5">Warranty Disclaimer.</span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c15"
        >The Services are made available under the agreement as is and as available, without
        warranties of any kind, either express or implied. Supplier expressly disclaims all express
        and implied warranties, including, without limitation, warranties or conditions of
        satisfactory quality, merchantability, non-infringement, title, and fitness for a particular
        purpose for the Services.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c15"
        >Supplier does not warrant (a) uninterrupted, timely, or error-free Services, (b) that
        Supplier will correct any defects or prevent third-party disruptions or unauthorized
        third-party access, or (c) that Services are secure, available, accurate, private,
        confidential, appropriate, reliable, or complete.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="7">
    <li class="c4 li-bullet-0"><span class="c15">Indemnity. </span></li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0" id="h.2et92p0">
      <span class="c5"
        >Customer shall defend, indemnify, and hold Supplier, its affiliates, subsidiaries, their
        respective officers, directors, employees, agents, contractors, successors, and assigns
        harmless from and against any and all damages, losses, fines, penalties, costs, expenses,
        liabilities, and other fees (including, without limitation, reasonable legal fees) arising
        from or relating to any actual, alleged, or threatened claims, demands, investigations, or
        causes of action by third parties arising from or relating to Customer&rsquo;s use of the
        Services.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >If a third party asserts a claim against Customer that services offered by Supplier
        infringe a patent, copyright, or trademark, Supplier will defend Customer against that claim
        and pay amounts finally awarded by a court against Customer or included in a settlement
        approved by Supplier, provided that Customer promptly (a) notifies Supplier in writing of
        the claim, (b) supplies information requested by Supplier, and (c) allows Supplier to
        control, and reasonably cooperates in, the defense, settlement, and mitigation.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Supplier&rsquo;s obligation to defend does not apply to any claim based on (a)
        Customer&rsquo;s combination of Services with data, software, or documentation not supplied,
        recommended, documented, or approved by Supplier; or (b) Customer&rsquo;s unauthorized
        modifications to the Services. Supplier may, at its sole expense and sole discretion, (i)
        procure for Customer the right to continued use of such Services as contemplated herein,
        (ii) replace or modify such Services such that the alleged infringing portion thereof
        becomes non-infringing (iii) replace the infringing Item with a functionally equivalent
        non-infringing item, or (iv) terminate the Agreement and refund to the Customer any prepaid
        unused fees.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >This section 7 describes Customer&rsquo;s sole remedy against Supplier relating to
        third-party claims of patent, copyright, or trademark infringement.
      </span>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="8">
    <li class="c19">
      <h1 style="display: inline">
        <span class="c5">Limitation of </span><a id="id.tyjcwt"></a
        ><span class="c5">Liability.</span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c15"
        >Where permitted by applicable law, except for (a) Customer&rsquo;s obligations relating to
        payment, (b) Customer&rsquo;s obligations under sections 1.5, 1.6, and 1.7, (c)
        Customer&rsquo;s indemnity obligations, neither party is liable for special, incidental,
        exemplary, indirect, or consequential damages, including but not limited to loss of profits,
        business, reputation, opportunities, value, revenue, goodwill, or anticipated savings; or
        cost of replacement services. These limitations apply regardless of whether either party was
        advised of the possibility of such losses or damages or such losses or damages were
        otherwise foreseeable. Where permitted by applicable law, Supplier&#39;s entire liability
        for all claims arising from or relating to the agreement will not exceed 6 months&rsquo;
        services fees paid by Customer to Supplier, regardless of the basis of the claim.</span
      >
    </li>
  </ol>
  <a id="id.3dy6vkm"></a>
  <ol class="c11 lst-kix_list_2-0" start="9">
    <li class="c19">
      <h1 style="display: inline">
        <span class="c5">Privacy and Security.</span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5 c35">Supplier shall use commercially reasonable efforts comply with its </span
      ><span class="c5 c38"
        ><a
          class="c27"
          href="https://www.google.com/url?q=https://www.hrsignal.com/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1733747664464536&amp;usg=AOvVaw04SzQUkCrdBqu9ij1dGJxn"
          >Privacy Policy</a
        ></span
      ><span class="c5"
        >&nbsp;and applicable laws applicable to the provision of its Services in the United
        States.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5 c20"
        >Customer shall use commercially reasonable efforts to comply with its own privacy policy
        and applicable data privacy laws applicable to the use of Materials and personal
        information. Customer shall allow individuals to exercise their rights under applicable law,
        including, without limitation, notice, access, deletion, or correction.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="10">
    <li class="c19">
      <h1 style="display: inline"><span class="c6">Changes.</span></h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c2"
        >Supplier may, at Supplier&#39;s reasonable discretion, modify the Services from time to
        time, with prior written notice where practicable, without any additional liability.
        Supplier&rsquo;s modification to the Services will replace prior versions as of the
        effective date. Customer may not refuse these modifications to the Services. Notwithstanding
        the foregoing, Supplier is not required to modify the Services.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5 c25"
        >Except as otherwise provided, all changes to the Agreement must be in writing and signed by
        both parties.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="11">
    <li class="c19">
      <h1 style="display: inline">
        <span class="c5">Term and Termination.</span>
      </h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0" id="h.3znysh7">
      <span class="c5"
        >The term of the SaaS Agreement commences on the date the Customer signs the Order and SaaS
        Agreement electronically on Supplier&rsquo;s website or starts using the Services (whichever
        is earlier) and Supplier&rsquo;s acceptance of this Order and SaaS Agreement. Termination of
        this SaaS Agreement by either party automatically terminates all Orders.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >The term of the Agreement is set forth in the Order. The Agreement renews automatically
        unless Customer provides written notice to Supplier not to renew (a) at least 30 calendar
        days prior to an annual renewal if the Customer has selected an annual term in the Order or
        (b) at least 14 calendar days prior to a monthly renewal if the Customer has selected a
        monthly term in the Order.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Supplier may immediately, in its sole discretion and without prior written notice,
        temporarily suspend or limit Customer&#39;s use of the Services or the Agreement where
        Supplier reasonably suspects a breach of the Agreement. Supplier shall provide notice of the
        actions Customer must take to reinstate the Services. Supplier may terminate the Services or
        this Agreement without any additional liability for Customer&rsquo;s failure to take
        required actions.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >The Agreement automatically terminates upon the liquidation or insolvency of either party
        or the appointment of a trustee or receiver for either party.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Upon termination of the SaaS Agreement or applicable Order: (a) Supplier may immediately
        terminate Customer&rsquo;s access to Supplier&rsquo;s Services; and (b) Customer shall
        immediately cease using any portion of Supplier&rsquo;s Services.
      </span>
    </li>
  </ol>
  <a id="id.1t3h5sf"></a>
  <ol class="c11 lst-kix_list_2-1" start="6">
    <li class="c4 li-bullet-0" id="h.4d34og8">
      <span class="c5"
        >Promptly upon termination, Customer shall securely delete or destroy the Materials it has
        already incorporated into its systems or used for its business purposes. Upon request,
        Customer shall certify such deletion or destruction by its Chief Information Technology
        Officer, or equivalent, of Customer.</span
      >
    </li>
    <li class="c4 li-bullet-0" id="h.2s8eyo1">
      <span class="c5"
        >Promptly upon termination, Supplier shall securely delete or destroy Customer Content. Upon
        request, Supplier shall certify such deletion or destruction by its Chief Information
        Technology Officer, or equivalent, of Supplier.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="12">
    <li class="c19">
      <h1 style="display: inline"><span class="c5">Governing Law.</span></h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5"
        >This Agreement is governed by the laws of the State of Ohio, United States, without regard
        to conflict of law principles. Subject to section 12.3 below, the parties submit to the
        exclusive jurisdiction of, and venue in, the state or federal courts located in Cuyahoga
        County, Ohio, in any action or proceeding arising from or relating to this Agreement. The
        United Nations Convention for the International Sale of Goods does not apply to this
        Agreement.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Either party may seek injunctive or other equitable relief for actual or threatened breach
        of confidentiality, security, or intellectual property protections hereunder by Customer
        under the Agreement.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c15"
        >Except as otherwise stated in section 12.2 above, any dispute, claim, or controversy
        arising from or relating to the Services or this Agreement will be settled in binding
        arbitration between them in accordance with the commercial arbitration rules and procedures
        of the American Arbitration Association, as modified by this Agreement.</span
      ><span class="c5">&nbsp;</span
      ><span class="c15"
        >The arbitrator may award relief (including monetary, injunctive, and declaratory relief)
        only in favor of the party seeking relief and only to the extent necessary to provide relief
        necessitated by that party&rsquo;s individual claim or claims. Judgment upon the award
        rendered by the arbitrator may be entered in any court having jurisdiction thereof. The
        choice of law and venue provision in section 12.1 does not apply to the arbitration
        provision or any arbitrable disputes described herein. Instead, the Federal Arbitration Act
        applies to such disputes.</span
      >
    </li>
  </ol>
  <a id="id.17dp8vu"></a>
  <ol class="c11 lst-kix_list_2-1" start="4">
    <li class="c4 li-bullet-0">
      <span class="c15"
        >Each party irrevocably waives, to the fullest extent permitted by applicable law, any and
        all right to trial by jury in any legal proceeding arising from or relating to this
        Agreement.</span
      >
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-0" start="13">
    <li class="c19">
      <h1 style="display: inline"><span class="c5">General.</span></h1>
    </li>
  </ol>
  <ol class="c11 lst-kix_list_2-1 start" start="1">
    <li class="c4 li-bullet-0">
      <span class="c5"
        >This Agreement constitutes the entire agreement of the parties and supersedes all prior or
        contemporaneous understandings, representations, discussions, or agreements between the
        parties relating to its subject matter.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >In the event of a conflict between the Order and the SaaS Agreement, the Order governs.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >If any provision of the Agreement is invalid or unenforceable, the remaining provisions
        remain in full force and effect.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >The waiver of a breach of any term of the Agreement, which must be in writing, will not
        operate as or be construed to be a waiver of any other previous or subsequent breach of the
        Agreement.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Customer agrees not to hire or attempt to hire for employment, either directly or
        indirectly, an employee, agent, or contractor of Supplier during the term of this Agreement
        and for a period of 2 years after termination of this Agreement.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Supplier is an independent contractor. Customer is responsible for its use of Supplier
        Services. Each party is responsible for determining the assignment of its and its
        affiliates&rsquo; personnel, and their respective contractors and vendors, and for their
        direction, control, and compensation.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Supplier owns the information generated as a result of the use of its Services. Supplier
        may only internally use this information for providing or improving the Services, but not
        share it externally in violation of the Agreement.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Except as otherwise provided herein, Customer may not assign the Agreement, in whole or in
        part, without the prior written consent of Supplier. Either party may assign the Agreement
        with 30 calendar days&rsquo; prior written notice to the other party upon a merger,
        acquisition, or purchase or sale of substantially all of its assets so long as such
        transaction is not with a competitor of the non-assigning party. Supplier may assign the
        Agreement at its sole discretion. Any assignment, transfer, or delegation in violation of
        this section is void.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >All notices under the Agreement must be in writing and sent to the business address
        specified in the Agreement, unless a party designates a different address in writing.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >This Agreement may be executed in two or more counterparts, all of which when taken
        together shall be considered one and the same agreement and become effective when
        counterparts have been signed by each party and delivered to the other party.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >The parties consent to the use of electronic signatures (including use of online form
        checkboxes) and communication. Any reproduction of the Agreement made by reliable means is
        considered an original.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >This Agreement does not create any third-party rights. Neither party will bring a legal
        action arising from or relating to the Agreement more than two years after the cause of
        action arose.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Any terms that by their nature extend beyond the Agreement termination remain in effect
        until satisfied and apply to successors and assignees.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Unless Customer requests otherwise in writing in advance, Customer authorizes Supplier to
        use Customer&rsquo;s logo, name, or trademark on its website or other media as a
        customer.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Neither party is responsible for failure to fulfill its obligations under the Agreement due
        to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may
        not be delayed under such causes beyond 15 calendar days.
      </span>
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >The parties hereto are sophisticated, commercial parties. The Agreement will not be
        construed against the drafter.</span
      >
    </li>
    <li class="c4 li-bullet-0">
      <span class="c5"
        >Parties acknowledge that they have read the Agreement, understand it, and agree to be bound
        by its terms. The person signing on behalf of each party is authorized to do so.</span
      >
    </li>
  </ol>
  <div>
    <p class="c14"><span class="c2"></span></p>
    <p class="c37">
      <span class="c5 c25">v.20</span>
    </p>
    <p class="c17"><span class="c2"></span></p>
  </div>
</template>

<style scoped type="text/css">
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.'
    counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.'
    counter(lst-ctn-kix_list_2-6, decimal) '.' counter(lst-ctn-kix_list_2-7, decimal) '. ';
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '. ';
}
.lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '. ';
}
.lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.'
    counter(lst-ctn-kix_list_2-6, decimal) '.' counter(lst-ctn-kix_list_2-7, decimal) '.'
    counter(lst-ctn-kix_list_2-8, decimal) '. ';
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '. ';
}
.lst-kix_list_1-2 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '. ';
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '. ';
}
.lst-kix_list_1-4 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '. ';
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
    counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) '. ';
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '. ';
}
.lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
    counter(lst-ctn-kix_list_1-6, decimal) '. ';
}
li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}
.lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}
.lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '. ';
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
.lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
    counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) '.'
    counter(lst-ctn-kix_list_1-8, decimal) '. ';
}
.lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '. ';
}
.lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c13 {
  border-right-style: solid;
  padding: 1.4pt 5.8pt 1.4pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 129.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c18 {
  border-right-style: solid;
  padding: 1.4pt 5.8pt 1.4pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 131.6pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c7 {
  border-right-style: solid;
  padding: 1.4pt 5.8pt 1.4pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 134.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c19 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: justify;
  margin-right: 3.6pt;
}
.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c10 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c16 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.c6 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c24 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Arial';
  font-style: normal;
}
.c12 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: left;
}
.c4 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: justify;
  margin-right: 3.6pt;
}
.c22 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c31 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c30 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: left;
}
.c8 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: left;
  height: 11pt;
}
.c17 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.06;
  text-align: left;
  height: 11pt;
}
.c29 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: justify;
  margin-right: 3.6pt;
}
.c32 {
  margin-left: 31.5pt;
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1;
  text-align: center;
}
.c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c37 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c26 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: center;
}
.c21 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  text-align: center;
}
.c33 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  text-align: left;
}
.c20 {
  color: #212529;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c0 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c38 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c5 {
  font-size: 10pt;
  font-family: 'Calibri';
  font-weight: 400;
}
.c23 {
  background-color: #ffffff;
  max-width: 525.6pt;
  padding: 57.6pt 43.2pt 57.6pt 43.2pt;
}
.c15 {
  font-size: 10pt;
  font-family: 'Calibri';
  font-weight: 700;
}
.c34 {
  font-weight: 700;
  font-family: 'Calibri';
}
.c27 {
  color: inherit;
  text-decoration: inherit;
}
.c11 {
  padding: 0;
  margin: 0;
}
.c25 {
  color: #000000;
}
.c36 {
  background-color: #ffff00;
}
.c9 {
  height: 0pt;
}
.c28 {
  height: 11pt;
}
.c35 {
  color: #212529;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
h1 {
  padding-top: 6pt;
  color: #000000;
  font-weight: 700;
  font-size: 9pt;
  padding-bottom: 0pt;
  font-family: 'Arial';
  line-height: 1;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Arial';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Arial';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Arial';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
</style>
