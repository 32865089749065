import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    isAuthenticated: false,
    user: null
  }),
  getters: {
    isLoggedIn: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
    getUserName: (state) => `${state.user.first_name} ${state.user.last_name ?? ''}`,
    getDaysUntilFreeTrialEnds: (state) => {
      const currentDate = new Date()
      const userCreatedAt = new Date(state.user.created_at_for_payment)
      const daysLeftInTrialDateDiff = currentDate - userCreatedAt
      const daysLeftInTrial = 14 - Math.round(daysLeftInTrialDateDiff / (1000 * 60 * 60 * 24))

      return daysLeftInTrial < 0 ? 0 : daysLeftInTrial
    }
  },
  actions: {
    login(user) {
      this.isAuthenticated = true
      this.user = user
    },
    logout() {
      this.isAuthenticated = false
      this.user = null
    },
    setSubscriptionStatusToActive() {
      this.user.subscriptionStatus = 'active'
    },
    setSubscriptionStatus(subscriptionStatus: string) {
      this.user.subscriptionStatus = subscriptionStatus
    }
  }
})
