<template>
  <Head title="Lookalikes" />
  <div class="flex flex-col items-center">
    <div class="flex flex-col gap-6 max-w-md text-center">
      <h2 class="font-extrabold text-9xl text-gray-600 dark:text-gray-100">
        <span class="sr-only">Error</span>404
      </h2>
      <p class="text-2xl md:text-3xl dark:text-gray-300">Sorry, we couldn't find this page.</p>
      <BaseButton
        class="mt-4 justify-center text-xl font-semibold rounded"
        type="button"
        text="Go Home"
        @on-click="goHome"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const goHome = () => {
  router.push({ name: 'homePage' })
}
</script>
