<template>
  <Head title="Lookalikes" />

  <div class="flex justify-between">
    <div class="mb-4 flex">
      <h1
        v-if="lookalikesData.archetypeData && lookalikesData.archetypeData.name !== ''"
        class="mt-0 mb-0 self-center text-3xl blinker-semibold leading-9 text-slate-800"
      >
        <span>
          {{ lookalikesData.archetypeData.name }}
        </span>
        <span class="font-light capitalize"> ({{ lookalikesData.archetypeData.position }})</span>
        <a :href="linkedinUrl" target="_blank" rel="noreferrer">
          <LinkedinIcon class="ml-2 inline h-[20px] w-[20px] align-middle" />
        </a>
      </h1>
    </div>
    <div class="flex gap-3">
      <BaseButton
        class="self-center !mr-0"
        text="Edit Search"
        bg-color="green"
        @click="showEditModal = true"
      />
      <RouterLink
        class="black-theme-white-link bg-primary-500 hover:bg-primary-400 self-center focus:outline-acne inline-flex h-9 items-center rounded px-3 text-sm font-bold text-white shadow ring-primary-200 last:mr-0 focus:ring dark:text-gray-800 dark:ring-gray-600"
        to="/"
        >New Search</RouterLink
      >
    </div>
  </div>

  <EditArchetypeModal
    v-if="showEditModal"
    @close="showEditModal = false"
    @search-edited="handleSearchEdited"
    @searching-for-lookalikes="$emit('stop-fetching-results')"
    @error="error = true"
  />

  <SearchResultsTable
    v-if="store.searchResults"
    :candidate-data="store.searchResults.candidateData"
    :archetype-data="lookalikesData.archetypeData"
    :searched-job-title="
      lookalikesData.archetypeData.revisedPosition ?? lookalikesData.archetypeData.position
    "
    :is-loading="loading"
    table-height="max-h-[65vh]"
    @shortlist-failed="$emit('shortlist-failed')"
    @shortlist-succeed="$emit('shortlist-succeed')"
  >
    <template #table-header>
      <ArchetypeTable :archetype-data="store.searchResults.archetypeData" />
    </template>
  </SearchResultsTable>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import LinkedinIcon from '@/components/icons/LinkedinIcon.vue'
import BaseButton from '@/components/BaseButton.vue'
import { useLookalikesStore } from '@/stores/lookalikes'
import EditArchetypeModal from './EditArchetypeModal.vue'
import SearchResultsTable from '../Components/SearchResultsTable.vue'
import ArchetypeTable from './ArchetypeTable.vue'

const emit = defineEmits([
  'shortlist-failed',
  'shortlist-succeed',
  'search-edited',
  'stop-fetching-results'
])
const store = useLookalikesStore()

const error = ref(false)
const loading = ref(false)
const showEditModal = ref(false)

const lookalikesData = computed(() => store.searchResults)

const linkedinUrl = computed(
  () => `https://www.linkedin.com/in/${lookalikesData.value.archetypeData.profileId}`
)

const handleSearchEdited = () => {
  emit('search-edited')
}
</script>
