<template>
  <div v-if="!userCreated" class="h-screen">
    <div class="border-b">
      <div class="flex justify-between lg:max-w-[70%] m-auto">
        <div>
          <a href="https://www.hirelookalikes.com">
            <img
              class="mx-auto my-3 rounded-xl lg:w-[250px] w-[150px]"
              src="@/assets/lookalikes-new.png"
              alt="logo"
            />
          </a>
        </div>
        <RouterLink class="self-center" to="/login">
          <BaseButton class="!rounded-full lg:w-20" text="Login" text-align-center />
        </RouterLink>
      </div>
    </div>

    <div
      class="flex gap-5 lg:gap-20 max-w-[90%] lg:max-w-[70%] m-auto mt-8 lg:mt-12 justify-center flex-col lg:flex-row"
    >
      <div class="flex flex-col justify-center">
        <div>
          <h1
            class="text-xl lg:text-2xl 2xl:text-3xl text-center m-auto lg:text-left inter-medium max-w-[750px] 2xl:leading-[50px] leading-[30px]"
          >
            <!-- Join Lookalikes and let AI reveal top candidates in seconds. -->
            View <span class="text-[#225DE6]">qualified</span> candidates instantly<br />
            AI ranks people by <span class="text-[#225DE6]">Predicted Interest™</span><br />
            in a new job
          </h1>
          <ul class="mt-8 leading-8 lg:text-sm 2xl:text-xl hidden lg:block">
            <li class="flex gap-2"><GreenCircleWithCheckMark :width="16" />14-day FREE trial</li>
            <li class="flex gap-2">
              <GreenCircleWithCheckMark :width="16" /> Discover top-ranked passive candidates
            </li>
            <li class="flex flex-col">
              <div class="flex gap-2">
                <GreenCircleWithCheckMark :width="16" />
                <span>People Analytics included:</span>
              </div>
              <div class="pl-6">
                <p>- Salary Averages & Position Hiring Demand</p>
                <p>- Benchmarks on Role & Company Tenure</p>
              </div>
            </li>
          </ul>
          <div class="lg:flex lg:flex-col mt-12 hidden">
            <p class="text-xl inter-medium text-[#9999] mb-[-16px]">AS SEEN ON:</p>
            <div class="flex ml-[-12px]">
              <img class="lg:w-[100px] 2xl:w-[150px]" src="@/assets/crains.png" alt="" />
              <img class="lg:w-[100px] 2xl:w-[150px]" src="@/assets/fc-sm.png" alt="" />
              <img class="lg:w-[100px] 2xl:w-[150px]" src="@/assets/tc2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-[450px] m-auto lg:m-0 border p-4 rounded bg-white">
        <ul class="mb-5" v-if="formValidationErrors">
          <li class="text-rose-600" v-for="(error, idx) in formValidationErrors" :key="idx">
            {{ error }}
          </li>
        </ul>
        <p class="text-rose-600 mb-2" v-if="errorMessage">{{ errorMessage }}</p>
        <p class="pb-2 text-primary-500 font-bold text-sm lg:text-[20px]">
          INSTANT ACCESS. NO CREDIT CARD.
        </p>
        <form @submit.prevent="register">
          <Input
            class="mb-6"
            placeholder="Full Name"
            type="text"
            label-size="small"
            required
            max-length="100"
            min-length="2"
            data-hj-allow
            @input-change="(value) => (fullName = value)"
          />
          <Input
            class="mb-6"
            placeholder="Organization"
            type="text"
            label-size="small"
            required
            max-length="100"
            min-length="2"
            data-hj-allow
            @input-change="(value) => (organization = value)"
          />
          <Input
            class="mb-6"
            placeholder="Job Title"
            type="text"
            label-size="small"
            max-length="100"
            min-length="2"
            data-hj-allow
            @input-change="(value) => (jobTitle = value)"
          />
          <Input
            class="mb-6"
            placeholder="Work Email"
            name="email"
            type="email"
            label-size="small"
            required
            autocomplete="off"
            data-hj-allow
            @input-change="(value) => (email = value)"
          />

          <Input
            class="mb-6"
            placeholder="Password"
            type="password"
            label-size="small"
            min-length="6"
            max-length="100"
            autocomplete="off"
            required
            @input-change="(value) => (password = value)"
          />
          <div class="flex self-center pl-1">
            <CheckBoxInput class="self-center" :required="true" />
            <span class="ml-1">
              I hereby agree to the
              <span
                class="cursor-pointer text-indigo-600 inter-medium text-sm"
                @click="showTermsAndConditions = true"
                >SaaS agreement</span
              >
              and order
            </span>
          </div>
          <BaseButton
            class="w-full mt-8 text-lg rounded-full"
            text-align-center
            bg-color="blue"
            text="Agree and Submit"
            :large-text="true"
            :align-loader-center="true"
            type="submit"
            :loading="loading"
          />
          <p class="pt-4 text-center">✔️GDPR &nbsp;&nbsp; ✔️CCPA &nbsp;&nbsp; ✔️SSL</p>
        </form>
      </div>
    </div>

    <section class="max-w-[80%] sm:max-w-[400px] lg:max-w-[600px] m-auto mt-10 mb-10">
      <h2 class="text-center text-xl lg:text-3xl inter-medium">Our Customers Love Us</h2>
      <div class="flex justify-center mt-4">
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>
      <p class="mt-4 text-sm lg:text-base">
        "I love using Lookalikes to scout for new tech talent because it allows me to refine my
        search and find relevant candidates quickly and easily!"
        <br />
        <i>- Kat A, Tech Sales Recruiter</i>
      </p>
    </section>
    <footer class="border-t border-gray-200 p-2 text-sm h-10 text-gray-500 text-center">
      ©2024 HR Signal Technology Inc. DBA Lookalikes™ |
      <a
        class="text-primary-500"
        href="https://www.hirelookalikes.com/privacy-policies-legal/"
        target="_blank"
        >Privacy, Policies & Legal</a
      >
    </footer>
    <TermsOfService v-if="showTermsAndConditions" @close="showTermsAndConditions = false" />
  </div>
  <div v-else class="mt-[50px] flex flex-col items-center gap-4 h-screen">
    <GreenCircleWithCheckMark :width="70" />
    <p>User Created. redirecting to Lookalikes</p>
    <div class="loader"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { update } from '@intercom/messenger-js-sdk'

import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import TermsOfService from '@/components/TermsOfService.vue'
import { useUserStore } from '@/stores/user'
import StarIcon from '@/components/icons/StarIcon.vue'
import GreenCircleWithCheckMark from '@/components/icons/GreenCircleWithCheckMark.vue'
import CheckBoxInput from '@/components/inputs/CheckBoxInput.vue'

onMounted(() => {
  update({ hide_default_launcher: true })
})

const router = useRouter()
const userStore = useUserStore()

const showTermsAndConditions = ref(false)
const fullName = ref('')
const organization = ref('')
const jobTitle = ref('')
const email = ref('')
const password = ref('')
const errorMessage = ref('')
const loading = ref(false)
const userCreated = ref(false)
const formValidationErrors = ref<null | Record<string, string[]>>(null)

const register = async () => {
  loading.value = true

  await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

  try {
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/register`, {
      fullName: fullName.value,
      organization: organization.value,
      jobTitle: jobTitle.value,
      email: email.value,
      password: password.value
    })

    const userRes = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

    const user = userRes.data
    userStore.login(user)

    formValidationErrors.value = null

    userCreated.value = true
  } catch (error) {
    if (error.response.status === 400) {
      formValidationErrors.value = Object.values(error.response.data).flat()
    } else {
      captureException(error)
      errorMessage.value = 'Oops,something went wrong. please contact support'
    }
  } finally {
    if (userCreated.value) {
      setTimeout(() => {
        redirectToPlatform()
      }, 3600)
    }
    loading.value = false
  }
}

const redirectToPlatform = () => {
  const redirect = router.currentRoute.value.query.redirect || '/'
  router.push(redirect)
}
</script>

<style scoped>
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  background: linear-gradient(#dc1818 0 0) bottom/100% 0% no-repeat #ccc;
  -webkit-mask:
    radial-gradient(circle at 60% 65%, #000 62%, #0000 65%) top left,
    radial-gradient(circle at 40% 65%, #000 62%, #0000 65%) top right,
    linear-gradient(to bottom left, #000 42%, #0000 43%) bottom left,
    linear-gradient(to bottom right, #000 42%, #0000 43%) bottom right;
  -webkit-mask-size: 50% 50%;
  -webkit-mask-repeat: no-repeat;
  animation: l19 4s infinite linear;
}
@keyframes l19 {
  90%,
  100% {
    background-size: 100% 100%;
  }
}
</style>
